<template>
  <div
    id="app"
    :class="{
      [$style.land]: el.is.land,
      [$style.port]: el.is.port,
      [$style.phone]: el.is.phone,
      [$style.big]: el.is.big,
    }"
  >
    <!-- STEP 1 -->
    <div :class="$style.stepOne">
      <h1 :class="$style.title" v-if="steponeheader">{{ steponeheader }}</h1>
      <p :class="$style.description" v-if="steponetext">
        {{ steponetext }}
      </p>

      <main :class="$style.stepOneForm">
        <div :class="$style.formBox">
          <div :class="$style.houseHolder">
            <div :class="$style.imgWrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="323"
                height="148"
                viewBox="0 0 323 148"
                :class="$style.houseImg"
              >
                <rect
                  x="4"
                  y="4"
                  width="315"
                  height="140"
                  style="fill: #bbccc5"
                />
                <rect
                  id="hausanker-my-part"
                  x="38"
                  y="6"
                  :width="housePart"
                  height="139"
                  style="fill: #f3f3f3"
                />
                <path
                  d="M0,148V0H323V148Zm206.61-3.9v-47l13.14,2.46v44.5h2.59V100l11.15,2.09v42h15.09V88.26H261L233.23,7.92,155.41,22.8l-6.18.85,15.65,25.11L127.61,38,103.75,13.44,81.59,44.64v8.22l-10.15-1L38.7,83.16h21V144.1h5.79V104.36l12.37-1V144.1H90.39V102.35l13.44-1.1V144.1h3.91V100.93l16-1.22V144.1H150V97.52l25.53-2V144.1Z"
                  style="fill: #f3f3f3"
                />
                <path
                  d="M191.21,148H58.41v-3.9H-381v-1.25H58.41V84.41H35.59l33-31.6L57,51.74,94.05,0l54.68,22.84,16.15,25.92L127.61,38,103.75,13.44,81.59,44.64V144.1h-5V104.68l-9.87.83V144.1h-7v2.65H190V144.1H174.29V96.83l-23,1.84V144.1H132.8V75.74l-3.45.31V144.1h-6.89v-43l-13.47,1v42h-6.42V102.6l-10.93.9v40.6h-5V50l5-.58V75l10.93-1V48.4l6.42-.67V73.24l13.47-1.14V46.45l11.59-1.17v29.1l56.14-4.94,28.68-49,3.38,8.13,7.9-13.78-74.74,8-.18-1.23L234.06,6.49l.17.52,28.48,82.5H249.84v53.34H337v1.25H248.58V88.26H261L233.23,7.92l-62.82,12,62-6.67L222.86,30,246,85.64V144.1H232.24v-41l-8.65-1.62V144.1H218.5V100.64l-10.63-2V144.1H191.21Zm42.28-5.15h11.27v-57l-22.69-54.5L200,69.83l-8.84.78v72.25h15.41V97.14l13.14,2.46v43.25h2.59V100l11.15,2.09v40.75Zm-57.94,0H190V70.72l-55.91,4.91v67.22h16V97.52l25.53-2Zm-51.84,0h4.39V74.9l4.7-.41V46.66l-9.09.91V73.24l-16,1.35V49.11l-3.91.41V75.11l-13.44,1.2V50.83l-2.54.3v91.73h2.54V102.35l13.44-1.1v41.6h3.91V100.93l16-1.22Zm-45.88,0h2.51V44.24l23.25-32.75,24.69,25.38,33.82,9.79L147.87,23.83,94.49,1.53,59.24,50.7l12.2,1.12L38.7,83.16h21v59.69h5.79V104.36l12.37-1ZM218.68,23.22l-27,46.09,7.57-.67,22.18-38.71Z"
                  style="fill: #4b6e5a"
                />
              </svg>
              <!-- <img
                :class="$style.houseImg"
                alt="my-house"
                src="@/assets/my-house.svg"
              /> -->
            </div>
            <div :class="$style.percentageButtons">
              <div :class="$style.percentageBtnLeft">
                <button>{{ myPart }}%</button>
                <label>Anteil, der Ihnen weiterhin gehört</label>
              </div>
              <div :class="$style.percentageBtnRight">
                <button>{{ loanPart }}%</button>
                <label>Anteil, den Sie in Geld umwandeln</label>
              </div>
            </div>
          </div>
          <div :class="$style.inputsHolder">
            <h3 :class="$style.formTitle" style="font-family: Arya">
              Auszahlung berechnen
            </h3>
            <div>
              <div :class="$style.labelHolder">
                <label :class="$style.labelTitle"
                  >Wert Ihrer Immobilie
                  <BaseTooltip
                    :text="estimatedTooltip"
                    position="absolute"
                    :class="$style.explanationTooltip"
                  >
                    <span :class="$style.tooltip"
                      ><img
                        :class="$style.houseImg"
                        alt="tooltip"
                        src="@/assets/tooltip.svg" /></span
                  ></BaseTooltip>
                </label>
                <label :class="$style.labelValue" style="font-family: Arya">
                  <span
                    :class="$style.valuePrefix"
                    style="font-family: 'Open Sans'"
                  >
                    ca.</span
                  >
                  {{ numberWithPoints(estimated) }} €</label
                >
              </div>
              <div :class="$style.inputHolder">
                <button
                  :class="$style.rangeIconMinus"
                  @click="minusEstimatedHandler"
                >
                  <span :class="$style.plusMinus">-</span>
                </button>

                <input
                  :class="$style.inputRange"
                  :style="{ background: inputColor.estimated }"
                  type="range"
                  v-model.number="estimated"
                  @input="onEstimatedRange"
                  :min="estimatedMin"
                  :max="estimatedMax"
                  :step="estimatedStep"
                />
                <button
                  :class="$style.rangeIconPlus"
                  @click="plusEstimatedHandler"
                >
                  <span :class="$style.plusMinus">+</span>
                </button>
              </div>
            </div>
            <div :class="$style.inputGroup">
              <div :class="$style.labelHolder">
                <label :class="$style.labelTitle"
                  >Wunschauszahlung
                  <BaseTooltip
                    :text="payoutTooltip"
                    position="absolute"
                    :class="$style.explanationTooltip"
                  >
                    <span :class="$style.tooltip"
                      ><img
                        :class="$style.houseImg"
                        alt="tooltip"
                        src="@/assets/tooltip.svg" /></span
                  ></BaseTooltip>
                </label>
                <label :class="$style.labelValue" style="font-family: Arya">
                  {{ numberWithPoints(Math.round(payout)) }} €</label
                >
              </div>
              <div :class="$style.inputHolder">
                <button
                  :class="$style.rangeIconMinus"
                  @click="minusPayoutHandler"
                >
                  <span :class="$style.plusMinus">-</span>
                </button>

                <input
                  :class="$style.inputRange"
                  :style="{ background: inputColor.payout }"
                  type="range"
                  v-model.number="payout"
                  @input="onPayoutRange"
                  :min="payoutMin"
                  :max="payoutMax"
                  :step="payoutStep"
                />

                <button
                  :class="$style.rangeIconPlus"
                  @click="plusPayoutHandler"
                >
                  <span :class="$style.plusMinus">+</span>
                </button>
              </div>
            </div>
            <div :class="$style.inputGroup">
              <div :class="$style.labelHolder">
                <label :class="$style.labelTitle"
                  >Monatliches Nutzungsentgelt
                  <BaseTooltip
                    :text="feeTooltip"
                    position="absolute"
                    :class="$style.explanationTooltip"
                  >
                    <span :class="$style.tooltip"
                      ><img
                        :class="$style.houseImg"
                        alt="tooltip"
                        src="@/assets/tooltip.svg"
                    /></span>
                  </BaseTooltip>
                </label>
                <label :class="$style.labelValue" style="font-family: Arya">
                  <span
                    :class="$style.valuePrefix"
                    style="font-family: 'Open Sans'"
                    >nur </span
                  >{{ numberWithPoints(Math.round(fee)) }} €</label
                >
              </div>
              <!-- <div :class="$style.inputHolder" style="border-bottom: none">
                <button :class="$style.rangeIconMinus" @click="minusFeeHandler">
                  <span :class="$style.plusMinus">-</span>
                </button>

                <input
                  :class="$style.inputRange"
                  :style="{ background: inputColor.fee }"
                  type="range"
                  v-model.number="fee"
                  @input="onFeeRange"
                  :step="feeStep"
                  :min="feeMin"
                  :max="feeMax"
                />

                <button :class="$style.rangeIconPlus" @click="plusFeeHandler">
                  <span :class="$style.plusMinus">+</span>
                </button>
              </div> -->
            </div>
            <div :class="$style.buttonsHolder">
              <button
                :class="$style.buttonLeft"
                @click="goTo('/kontakt', false)"
              >
                Können wir ihnen helfen?
              </button>
              <button
                :class="$style.buttonRight"
                @click="goTo('/angebot-anfordern', true)"
              >
                Unverbindliches Angebot
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- STEP 2 -->
    <div v-if="!onlyfirstslide" :class="$style.stepTwo">
      <div :class="$style.topImageHolder">
        <img
          v-if="isMobile"
          :class="$style.topImg"
          alt="top-image"
          src="@/assets/stepTwo-mobile.jpeg"
        />
        <img
          v-else
          :class="$style.topImg"
          alt="top-image"
          src="@/assets/stepTwo.jpeg"
        />
      </div>
      <div :class="$style.stepTwoForm">
        <h3 :class="$style.stepTwoTitle">
          {{ steptwoheader }}
        </h3>
        <p :class="$style.stepTwoDescription">
          {{ steptwotextpartone }}
        </p>
        <p
          :class="{
            [$style.stepTwoDescription]: true,
            [$style.stepTwoDescriptionPartTwo]: true,
          }"
        >
          {{ steptwotextparttwo }}
        </p>
        <div :class="$style.slider">
          <button
            :class="{
              [$style.rangeIconMinus]: true,
              [$style.rangeMinusSlider]: true,
            }"
            @click="minusYearSaleHandler"
          >
            <span :class="$style.plusMinus">-</span>
          </button>
          <div :class="$style.sliderContainer">
            <input
              id="saleYear"
              v-model.number="yearSale"
              :max="yearSaleMax"
              :min="yearSaleMin"
              :step="yearSaleStep"
              :class="$style.inputRange"
              :style="{ background: inputColor.yearSale }"
              type="range"
            />
            <label
              for="10"
              :class="$style.range10"
              :style="yearSale > 10 ? { background: '#cc6b4a' } : {}"
              ><span
                :class="{
                  [$style.labelNumber]: yearSale,
                  [$style.labelTextAndNumber]: yearSale == 10,
                }"
                >10 {{ yearSale === 10 && !el.is.land ? 'Jahre' : '' }}</span
              ></label
            >
            <label
              for="15"
              :class="$style.range15"
              :style="yearSale > 15 ? { background: '#cc6b4a' } : {}"
              ><span
                :class="{
                  [$style.labelNumber]: yearSale,
                  [$style.labelTextAndNumber]: yearSale == 15,
                }"
                >15 {{ yearSale == 15 && !el.is.land ? 'Jahre' : '' }}</span
              ></label
            >
            <label
              for="20"
              :class="$style.range20"
              :style="yearSale > 20 ? { background: '#cc6b4a' } : {}"
              ><span
                :class="{
                  [$style.labelNumber]: yearSale,
                  [$style.labelTextAndNumber]: yearSale == 20,
                }"
                >20 {{ yearSale == 20 && !el.is.land ? 'Jahre' : '' }}</span
              ></label
            >

            <label
              for="25"
              :class="$style.range25"
              :style="yearSale > 25 ? { background: '#cc6b4a' } : {}"
              ><span
                :class="{
                  [$style.labelNumber]: yearSale,
                  [$style.labelTextAndNumber]: yearSale == 25,
                }"
                >25 {{ yearSale == 25 && !el.is.land ? 'Jahre' : '' }}</span
              ></label
            >
            <label
              for="30"
              :class="$style.range30"
              :style="yearSale == 30 ? { background: '#cc6b4a' } : {}"
              ><span
                :class="{
                  [$style.labelNumber]: yearSale,
                  [$style.labelTextAndNumber]: yearSale == 30,
                }"
              >
                30 {{ yearSale == 30 && !el.is.land ? 'Jahre' : '' }}</span
              ></label
            >
          </div>
          <button
            :class="{
              [$style.rangeIconPlus]: true,
              [$style.rangePlusSlider]: true,
            }"
            @click="plusYearSaleHandler"
          >
            <span :class="$style.plusMinus">+</span>
          </button>
        </div>
      </div>
      <div :class="$style.tableBox">
        <h4 :class="$style.tableTitle" style="font-family: Arya">
          Zusammenfassung
        </h4>
        <table :class="$style.table">
          <tr :class="$style.tableRow">
            <td :class="$style.withTooltip">
              Gesamtwert der Immobilie nach {{ yearSale }} Jahren
              <BaseTooltip
                :text="stepTwoTooltip"
                :table="true"
                position="absolute"
                :class="$style.explanationTooltip"
              >
                <span :class="$style.tooltip"
                  ><img
                    :class="$style.houseImg"
                    alt="tooltip"
                    src="@/assets/tooltip.svg"
                /></span>
              </BaseTooltip>
            </td>
            <td :class="$style.bold">{{ numberWithPoints(salePerYear) }} €</td>
          </tr>
          <!-- <tr :class="$style.tableRow">
            <td>Ihr Anteil</td>
            <td :class="$style.bold">
              {{ numberWithPoints(userShareMoney) }} €
            </td>
          </tr> -->
          <tr :class="$style.tableRow">
            <td>Ihre erste Auszahlung</td>
            <td :class="$style.bold">{{ numberWithPoints(payout) }} €</td>
          </tr>
          <tr :class="$style.tableRow">
            <td>Ihre zweite Auszahlung</td>
            <td :class="$style.bold">
              {{ numberWithPoints(secondPayment) }} €
            </td>
          </tr>
          <tr :class="$style.tableRow">
            <td :class="[$style.valueLabel, $style.withTooltip]">
              Ihr gespartes Durchführungsentgelt
              <BaseTooltip
                :text="implementationFeeTooltip"
                :table="true"
                position="absolute"
                :class="$style.explanationTooltip"
              >
                <span :class="$style.tooltip"
                  ><img
                    :class="$style.houseImg"
                    alt="tooltip"
                    src="@/assets/tooltip.svg"
                /></span>
              </BaseTooltip>
            </td>
            <td :class="$style.value">
              + {{ numberWithPoints(savedImplementation) }} €
            </td>
          </tr>
          <tr :class="$style.tableRow">
            <td :class="$style.totalLabel">Ihre Wertebilanz</td>
            <td :class="$style.total" style="font-family: Arya">
              {{ numberWithPoints(userShareMoney + payout) }} €
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { ResponsiveMixin } from 'vue-responsive-components'
import BaseTooltip from '@/components/BaseTooltip.vue'

export default {
  name: 'App',
  components: {
    BaseTooltip,
  },
  props: {
    onlyfirstslide: {
      type: Boolean,
      default: false,
    },
    steponeheader: {
      type: String,
      default: 'Teilverkauf-Rechner',
    },
    steponetext: {
      type: String,
      default:
        'Stellen Sie mithilfe der Regler den Wert Ihrer Immobilie sowie Ihre Wunschauszahlung ein. Der Rechner zeigt Ihnen automatisch die Höhe des Nutzungsentgelts an. ',
    },
    steptwoheader: {
      type: String,
      default:
        'Zukunftsoption: Falls Sie später mal einen Gesamtverkauf wünschen',
    },
    steptwotextpartone: {
      type: String,
      default:
        'Mit einem Teilverkauf an Hausanker halten Sie sich für die Zukunft alle Möglichkeiten offen, so auch den Gesamtverkauf. Sollten Sie irgendwann Ihren restlichen Immobilien-Anteil verkaufen wollen, profitieren Sie mit Hausanker von vielen Vorteilen. ',
    },
    steptwotextparttwo: {
      type: String,
      default:
        'Beispielsweise berechnen wir Ihnen kein Durchführungsentgelt. Bei Immobilienverkäufen bezahlen Sie nicht selten bis zu 5,5 Prozent des Verkaufserlöses. Dabei übernehmen wir für Sie die komplette Bürokratie und Abwicklung. Zudem profitieren Sie von Wertsteigerungen am Immobilienmarkt. Lassen Sie sich den hypothetischen zukünftigen Gesamtwert Ihrer Immobilie anzeigen, indem Sie den Regler bewegen. ',
    },
  },
  data() {
    return {
      estimatedTooltip:
        'Hier können Sie frei und unverbindlich einen Wert angeben, den Sie für Ihre Immobilie als angemessen erachten. Für eine realistische Preiseinschätzung Ihrer Immobilie sollten Sie die Lage, den Zustand und etwaige Ausstattungsmerkmale berücksichtigen. Der Mindestwert für einen Teilverkauf liegt bei 200.000 €.',
      payoutTooltip:
        'Der Auszahlungsbetrag kann maximal 50 % des Immobilienwertes betragen.',
      feeTooltip:
        'Im Gegenzug dafür, dass der Teilverkäufer die Immobilie auch nach dem Teilverkauf weiterhin vollständig selbst nutzen und/oder vermieten darf, entrichtet er ein Nutzungsentgelt an den Teilkäufer. Aufgrund der aktuellen Zinsentwicklung sind wir gezwungen das Nutzungsentgelt auf derzeit 4,99 % anzuheben. Diese Kondition bieten wir Ihnen festgeschrieben auf 10 Jahre an.',
      stepTwoTooltip:
        'Der Gesamtwert der Immobilie wird auf Basis einer angenommenen Wertsteigerung von 2,26 % pro Jahr.',
      implementationFeeTooltip:
        'Während Sie bei anderen Anbietern in der Regel bis zu 5,5 % Durchführungsentgelt bei einem späteren Gesamtverkauf der Immobilie zahlen müssen, fällt dieses bei der Deutschen Teilkauf weg. Sie können bei einem Gesamtverkauf durch uns also bis zu 5,5 % sparen.',
      inputColor: {
        yearSale:
          'linear-gradient(to right,#cc6b4a 0%,#cc6b4a 50%,#ece8e5 50%, #ece8e5 100%)',
        estimated:
          'linear-gradient(to right,#cc6b4a 0%,#cc6b4a 50%,#ece8e5 50%, #ece8e5 100%)',
        payout:
          'linear-gradient(to right,#cc6b4a 0%,#cc6b4a 50%,#ece8e5 50%, #ece8e5 100%)',
        fee: 'linear-gradient(to right,#cc6b4a 0%,#cc6b4a 50%,#ece8e5 50%, #ece8e5 100%)',
      },
      colorPayout: '',
      colorFee: '',
      // Variable for installment
      estimated: 790000,
      estimatedMin: 200000,
      estimatedMax: 2000000,
      estimatedStep: 10000,
      payout: 160000,
      payoutMin: 100000,
      payoutMax: '',
      payoutStep: 1000,
      fee: '',
      feeMin: 241,
      feeMax: '',
      feeStep: 1,
      yearSale: 19,
      yearSaleMin: 10,
      yearSaleMax: 30,
      yearSaleStep: 1,
      // const
      loanRate: 2.26,
    }
  },
  mixins: [ResponsiveMixin],
  breakpoints: {
    land: (el) => el.width <= 1199,
    port: (el) => el.width <= 991,
    phone: (el) => el.width <= 768,
    small: (el) => el.width <= 374,
    big: (el) => el.width > 1800,
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 800
    },
    housePart() {
      const house = (323 * 70) / 100

      return (house * this.myPart) / 100
    },
    loanPart() {
      return ((this.payout / this.estimated) * 100).toFixed(0)
    },
    myPart() {
      return (100 - this.loanPart).toFixed(0)
    },
    // Total value of the property after several years
    salePerYear() {
      return parseInt(
        this.estimated * Math.pow(1 + this.loanRate / 100, this.yearSale)
      )
    },
    firstLevel() {
      return parseInt(this.payout * 0.17 + this.estimated)
    },
    levelFirst() {
      return parseInt((this.estimated - this.payout) * 0.085)
    },
    levelSecond() {
      return parseInt((this.estimated - this.payout) * 0.17)
    },
    secondLevel() {
      return this.levelFirst + this.levelSecond + this.firstLevel
    },
    proportionPayout() {
      return parseFloat(((this.payout / this.estimated) * 100).toFixed(2))
    },
    dtAmount() {
      let dtAmount = 0
      if (this.salePerYear <= this.secondLevel) {
        dtAmount = parseInt(this.payout * 0.17 + this.payout)
      } else {
        let dtFirst = parseInt(
          ((this.salePerYear - this.secondLevel) / 100) * this.proportionPayout
        )

        let dtSecond = parseInt(this.payout * 0.17 + this.payout)
        dtAmount = parseInt(dtFirst + dtSecond)
      }
      return dtAmount
    },
    dtShare() {
      return parseFloat((this.dtAmount / this.salePerYear) * 100).toFixed(2)
    },
    userShareMoney() {
      return parseInt(this.salePerYear - this.dtAmount)
    },
    // The second amount the user will get it
    secondPayment() {
      // return parseInt((this.userShareMoney - this.payout).toFixed(2))
      return parseInt(this.userShareMoney.toFixed(2))
    },
    savedImplementation() {
      return parseInt(this.salePerYear * 0.055)
    },
    total() {
      return parseInt(
        this.payout + this.secondPayment + this.savedImplementation - this.fee
      )
    },
  },
  methods: {
    calculateFee() {
      this.fee = parseFloat((this.payout * 0.0499) / 12)
    },
    calculatePayout() {
      this.payout = Math.round((this.fee * 12) / 0.0499)
    },
    calculatePayoutMax() {
      this.payoutMax = Math.round(this.estimated / 2)
    },
    calculateFeeMax() {
      this.feeMax = Math.round((this.estimated / 2) * 0.0499)
    },
    goTo(url, sendData) {
      this.$emit('button', {
        sendData: sendData ? true : false,
        event: sendData ? 'rechner angebot anfordern' : '',
        url: url,
      })
    },
    numberWithPoints: (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    calculateRangeWidthEstimated() {
      const coef = 100 / ((this.estimatedMax - this.estimatedMin) / 0.01)
      let value = ((this.estimated - this.estimatedMin) / 0.01) * coef
      if (this.estimated + 0.01 > this.estimatedMax) {
        value = 100
      }
      if (this.estimated - 0.01 < this.estimatedMin) {
        value = 0
      }
      this.inputColor.estimated =
        'linear-gradient(to right, #cc6b4a 0%, #cc6b4a ' +
        value +
        '%, #ece8e5 ' +
        value +
        '%, #ece8e5  100%)'
    },
    calculateRangeWidthPayout() {
      const coef = 100 / ((this.payoutMax - this.payoutMin) / 0.01)
      let value = ((this.payout - this.payoutMin) / 0.01) * coef
      if (this.payout + 0.01 > this.payoutMax) {
        value = 100
      }
      if (this.payout - 0.01 < this.payoutMin) {
        value = 0
      }
      this.inputColor.payout =
        'linear-gradient(to right, #cc6b4a 0%, #cc6b4a ' +
        value +
        '%, #ece8e5 ' +
        value +
        '%, #ece8e5  100%)'
    },
    calculateRangeWidthFee() {
      const coef = 100 / ((this.feeMax - this.feeMin) / 0.01)
      let value = ((this.fee - this.feeMin) / 0.01) * coef
      if (this.fee + 0.01 > this.feeMax) {
        value = 100
      }
      if (this.fee - 0.01 < this.feeMin) {
        value = 0
      }
      this.inputColor.fee =
        'linear-gradient(to right, #cc6b4a 0%, #cc6b4a ' +
        value +
        '%, #ece8e5 ' +
        value +
        '%, #ece8e5  100%)'
    },
    calculateRangeWidthYearSale() {
      const coef = 100 / ((this.yearSaleMax - this.yearSaleMin) / 0.01)
      let value = ((this.yearSale - this.yearSaleMin) / 0.01) * coef
      if (this.yearSale + 0.01 > this.yearSaleMax) {
        value = 100
      }
      if (this.yearSale - 0.01 < this.yearSaleMin) {
        value = 0
      }
      this.inputColor.yearSale =
        'linear-gradient(to right, #cc6b4a 0%, #cc6b4a ' +
        value +
        '%, #ece8e5 ' +
        value +
        '%, #ece8e5  100%)'
    },

    plusMinusChange(input, type) {
      if (type === 'minus') {
        if (this[input + 'Min'] >= this[input] - step) {
          this[input] = Math.round(this[input + 'Min'])
        } else {
          this[input] = this[input] - step
        }
      }
      if (type === 'plus') {
        if (this[input + 'Max'] <= this[input] + step) {
          this[input] = Math.round(this[input + 'Max'])
        } else {
          this[input] = this[input] + step
        }
      }
    },
    onEstimatedRange() {
      this.calculatePayoutMax()
      this.calculateFeeMax()
      this.checkMinMaxPayout()
      this.checkMinMaxFee()
      this.calculateRangeWidthEstimated()
      this.calculateRangeWidthPayout()
      this.calculateRangeWidthFee()
    },
    onPayoutRange() {
      this.calculateFee()
      this.checkMinMaxPayout()
      this.checkMinMaxFee()
      this.calculateRangeWidthPayout()
      this.calculateRangeWidthFee()
    },
    onFeeRange() {
      this.calculatePayout()
      this.checkMinMaxPayout()
      this.checkMinMaxFee()
      this.calculateRangeWidthFee()
      this.calculateRangeWidthPayout()
    },
    checkMinMaxPayout() {
      if (this.payout > this.payoutMax) {
        this.payout = this.payoutMax
      }
      if (this.payout < this.payoutMin) {
        this.payout = this.payoutMin
      }
    },
    checkMinMaxFee() {
      if (this.fee > this.feeMax) {
        this.fee = this.feeMax
      }
      if (this.fee < this.feeMin) {
        this.fee = this.feeMin
      }
    },
    minusEstimatedHandler() {
      if (this.estimated - this.estimatedStep <= this.estimatedMin) {
        this.estimated = this.estimatedMin
      } else {
        this.estimated -= this.estimatedStep
      }
      this.calculatePayoutMax()
      this.calculateFeeMax()
      this.checkMinMaxPayout()
      this.checkMinMaxFee()
      this.calculateRangeWidthEstimated()
      this.calculateRangeWidthPayout()
      this.calculateRangeWidthFee()
    },
    plusEstimatedHandler() {
      if (this.estimated + this.estimatedStep >= this.estimatedMax) {
        this.estimated = this.estimatedMax
      } else {
        this.estimated += this.estimatedStep
      }
      this.calculatePayoutMax()
      this.calculateFeeMax()
      this.checkMinMaxPayout()
      this.checkMinMaxFee()
      this.calculateRangeWidthEstimated()
      this.calculateRangeWidthPayout()
      this.calculateRangeWidthFee()
    },
    minusPayoutHandler() {
      if (this.payout - this.payoutStep <= this.payoutMin) {
        this.payout = this.payoutMin
      } else {
        this.payout -= this.payoutStep
      }
      this.calculateFee()
      this.checkMinMaxFee()
      this.calculateRangeWidthPayout()
      this.calculateRangeWidthFee()
    },
    plusPayoutHandler() {
      if (this.payout + this.payoutStep >= this.payoutMax) {
        this.payout = this.payoutMax
      } else {
        this.payout += this.payoutStep
      }
      this.calculateFee()
      this.checkMinMaxFee()
      this.calculateRangeWidthPayout()
      this.calculateRangeWidthFee()
    },
    minusFeeHandler() {
      if (this.fee - this.feeStep <= this.feeMin) {
        this.fee = this.feeMin
      } else {
        this.fee -= this.feeStep
      }
      this.calculatePayout()
      this.checkMinMaxPayout()
      this.calculateRangeWidthFee()
      this.calculateRangeWidthPayout()
    },
    plusFeeHandler() {
      if (this.fee + this.feeStep >= this.feeMax) {
        this.fee = this.feeMax
      } else {
        this.fee += this.feeStep
      }
      this.calculatePayout()
      this.checkMinMaxPayout()
      this.calculateRangeWidthFee()
      this.calculateRangeWidthPayout()
    },
    minusYearSaleHandler() {
      if (this.yearSale - this.yearSaleStep <= this.yearSaleMin) {
        this.yearSale = this.yearSaleMin
      } else {
        this.yearSale -= this.yearSaleStep
      }
      this.calculateRangeWidthYearSale()
    },
    plusYearSaleHandler() {
      if (this.yearSale + this.yearSaleStep >= this.yearSaleMax) {
        this.yearSale = this.yearSaleMax
      } else {
        this.yearSale += this.yearSaleStep
      }
      this.calculateRangeWidthYearSale()
    },
  },
  watch: {
    estimated() {
      localStorage.setItem('immobilienwert', Math.round(this.estimated))
      localStorage.setItem('wunschbetrag', Math.round(this.payout))
    },
    payout() {
      localStorage.setItem('immobilienwert', Math.round(this.estimated))
      localStorage.setItem('wunschbetrag', Math.round(this.payout))
    },
    yearSale() {
      this.calculateRangeWidthYearSale()
    },
  },
  created() {
    store.dispatch('setEl', this.el)
    this.calculatePayoutMax()
    this.calculateFeeMax()
    this.calculateFee()
    this.calculateRangeWidthEstimated()
    this.calculateRangeWidthPayout()
    this.calculateRangeWidthFee()
    this.calculateRangeWidthYearSale()
  },
}
</script>

<style module lang="scss">
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: 'Open Sans';
}

.explanationTooltip {
  cursor: help;
  display: inline-block;
  // position: absolute;
  // top: -10px;
  // right: -32px;
  // height: 33px;
  // width: 27px;
}

.withTooltip {
  position: relative;
  padding-right: 18.5px;
}
.stepOne {
  position: relative;
  background: #f3f3f3;
  padding-right: 109px;
  max-width: 1290px;
  margin: 0 auto;
}

.title {
  color: #262825;
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
}

.description {
  color: #262825;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 36px;
  padding: 0px 258px;
}

.formTitle {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 38px;
}
.formBox {
  display: flex;
}
.houseHolder {
  padding-top: 70px;
  flex: 0 0 50%;
}
.houseImg {
  width: 100%;
  height: 100%;
}
.inputsHolder {
  padding-left: 17px;
  flex: 0 0 50%;
}
.percentageButtons {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}
.percentageBtnLeft {
  width: 132px;
  display: flex;
  flex-direction: column;
  margin-right: 17px;
}
.percentageBtnLeft button {
  display: inline-block;
  width: fit-content;
  background: #f3f3f3;
  color: #55806d;
  font-size: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 100%;
  font-weight: 700;
  border: 4px solid #55806d;
  border-radius: 30px;
  padding: 14px 31px;
}
.percentageBtnLeft label {
  color: #262825;
  font-size: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 100%;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
}

.percentageBtnRight {
  width: 132px;
  display: flex;
  flex-direction: column;
  margin-left: 17px;
}
.percentageBtnRight button {
  display: inline-block;
  width: fit-content;
  background: #779989;

  color: #ffffff;
  line-height: 1;
  font-size: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 100%;
  font-weight: 700;
  border: 4px solid #55806d;
  border-radius: 30px;
  padding: 14px 31px;
}
.percentageBtnRight label {
  color: #262825;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
}
.tooltip {
  display: inline-flex;
  width: 27px;
  height: 27px;
  border: 2px solid #262825;
  border-radius: 50%;
  padding: 5px 7px;
}
.labelHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.labelTitle {
  position: relative;
  color: #262825;
  font-size: 20px;
  line-height: 20px;
  padding-right: 18.5px;
}
.labelValue {
  color: #262825;
  font-size: 38px;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 38px;
  font-weight: 700;
}

.valuePrefix {
  color: #262825;
  font-size: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 20px;
  font-weight: 400;
}

.inputGroup {
  padding-top: 19px;
}

.inputHolder {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 19px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e3e3;
}

.inputRange {
  height: 8px;
  flex-grow: 1;
  margin: 0px 16px;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  cursor: grabbing;
  border-radius: 5px;
  background: transparent;
}

/* firefox */
.inputRange::-moz-range-thumb {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-top: -10px;
  background: #ffffff;
  border: 3px solid #cc6b4a;
  box-sizing: border-box;

  /* drop shadow btn primary */
  box-shadow: 0px 1px 4px rgba(38, 40, 37, 0.3),
    0px 6px 12px rgba(38, 40, 37, 0.1);
}

.inputRange::-webkit-slider-runnable-track {
  /* width: 100%; */
  height: 10px;
  border: none;
  border-radius: 5px;
}

.inputRange::-ms-fill-lower {
  background: #777777;
  border-radius: 10px;
}

.inputRange::-ms-fill-upper {
  background: #dddddd;
  border-radius: 10px;
}

.inputRange::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  margin-top: -15px;
  background: #ffffff;
  border: 3px solid #cc6b4a;
  box-sizing: border-box;

  /* drop shadow btn primary */
  box-shadow: 0px 1px 4px rgba(38, 40, 37, 0.3),
    0px 6px 12px rgba(38, 40, 37, 0.1);
}

.inputRange:focus {
  outline: none;
}

.rangeIconMinus {
  color: #cc6b4a;
  width: 64px;
  height: 64px;
  font-size: 28px;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid #cc6b4a;
  cursor: pointer;
  background: #ffffff;
}

.rangeIconMinus:hover {
  color: #333;
  border: 3px solid #333;
}

.rangeIconPlus {
  position: relative;
  color: #cc6b4a;
  width: 64px;
  height: 64px;
  font-size: 28px;
  border-radius: 50%;
  border: 3px solid #cc6b4a;
  cursor: pointer;
  background: #ffffff;
}

.rangeIconPlus:hover {
  color: #333;
  border: 3px solid #333;
}
.buttonsHolder {
  display: flex;
  margin-top: 19px;
}

.buttonLeft {
  cursor: pointer;
  color: #262825;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  width: 53.5%;
  margin-right: 16px;
  background: #ffffff;
  border: 2px solid #cecece;
  border-radius: 6px;
  padding: 20px 29px 18px;
  box-shadow: 0px 1px 4px rgba(38, 40, 37, 0.16),
    0px 6px 12px rgba(38, 40, 37, 0.04);
}
.buttonRight {
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
  width: 53.5%;
  flex-grow: 1;
  border: 2px solid #262825;
  background: #262825;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(38, 40, 37, 0.3),
    0px 6px 12px rgba(38, 40, 37, 0.1);
}

.stepTwo {
  padding-top: 66px;
  background: #f3f3f3;
}
.stepTwoForm {
  padding: 0px 258px;
  margin: 0 auto;
  max-width: 1290px;
}
.stepTwoTitle {
  font-family: Arya;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 38px;
  margin-top: 36px;
  text-align: center;

  color: #262825;
}
.stepTwoDescription {
  color: #262825;
  font-style: normal;
  font-weight: normal;
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 34px;
  padding: 0 20px;
  margin-top: 24px;
  text-align: center;
}
.stepTwoDescriptionPartTwo {
  font-size: 18px;
  line-height: 28px;
}

.topImg {
  width: 100%;
}
.tableBox {
  padding: 36px 258px;
  background: #ffffff;
  margin: 0 auto;
  max-width: 1290px;
}
.table {
  font-family: 'Open Sans';
  width: 100%;
  margin-top: 55px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #000000;
  line-height: 30px;
}
.table tr:last-child {
  border-bottom: none;
  margin-top: 36px;
}

.tableTitle {
  font-style: normal;
  font-weight: bold;
  font-family: Arya;
  font-size: 28px;
  line-height: 100%;
  color: #000000;
  margin-top: 36px;
}
.tableRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ece8e5;
  padding: 12px 0px;
}
.bold {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}
.valueLabel {
  font-weight: bold;
}
.total {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  text-align: right;
  color: #56806d;
}
.totalLabel {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #cc6b4a;
}

.value {
  font-size: 20px;
  color: #56806d;
  font-weight: 700;
}

.slider {
  position: relative;
  width: 100%;
  padding: 79px 0px;
  padding-bottom: 161px;
}
.rangePlusSlider {
  position: absolute;
  right: 0%;
  transform: translate(150%, -50%);
}
.rangeMinusSlider {
  position: absolute;
  left: 0%;
  transform: translate(-150%, -50%);
}
.sliderContainer {
  position: relative;
  display: flex;
}
.slider label {
  position: absolute;

  font-size: 16px;
  color: #363636;
}
.slider .inputRange {
  z-index: 99999999;
}
.slider label {
  content: '';
  position: absolute;
  left: 0%;
  top: 3px;
  transform: translate(0%, -50%);
  width: 20px;
  height: 20px;
  background: #ece8e5;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.sliderContainer label.range15 {
  left: 25%;
}
.sliderContainer label.range20 {
  left: 50%;
}
.sliderContainer label.range20 {
  transform: translate(-50%, -50%);
}
.sliderContainer label.range25 {
  left: 75%;
}
.sliderContainer label.range25 {
  transform: translate(-100%, -50%);
}
.sliderContainer label.range30 {
  left: 100%;
}
.sliderContainer label.range30 {
  transform: translate(-100%, -50%);
}
.sliderContainer input {
  width: 100%;
  margin: 0;
  display: block;
}
.inputRange {
  z-index: 0;
}
.labelNumber {
  font-size: 24px;
  line-height: 100%;
  font-weight: 700;
  font-family: Arya;
  color: #adb1ab;
  position: absolute;
  top: 48px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 140px;
  padding: 8px 12px;
  border-radius: 30px;
}
.labelTextAndNumber {
  background: #ffffff;
  color: #262825;
  font-size: 28px;
}

.rangeIconMinus span {
  font-size: 40px;
}
.rangeIconPlus span {
  font-size: 35px;
  line-height: 40px;
}

.land {
  .rangeIconMinus span {
    font-size: 40px;
  }
  .rangeIconPlus span {
    font-size: 35px;
    line-height: 25px;
  }

  .orangeTitle {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #cc6b4a;
  }
  .stepOne {
    padding-right: 0px;
  }
  .houseHolder {
    padding-top: 0px;
    padding-bottom: 16px;
  }
  .title {
    font-size: 34px;
    padding: 12px;
  }
  .description {
    font-size: 16px;
    font-weight: 600;
    margin: 0 auto;
    padding: 12px;
  }
  .stepOneForm {
    margin-top: 10px;
  }
  .formTitle {
    width: 100%;
    text-align: left;
    font-family: Arya;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 22px;
    margin-top: 24px;
  }

  .formBox {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
  }
  .tableBox {
    padding: 24px 16px;
  }
  .table {
    font-size: 16px;
    margin-top: 24px;
  }
  .tableTitle {
    font-size: 26px;
    margin-top: 0px;
  }

  .withTooltip {
    width: 65%;
    padding-right: 4.5px;
  }
  .percentageButtons {
    padding: 0px 16px;
    justify-content: center;
    margin-top: 19px;
  }
  .percentageBtnLeft button {
    font-size: 24px;
  }
  .percentageBtnLeft label {
    font-size: 14px;
  }
  .percentageBtnRight button {
    font-size: 24px;
  }
  .percentageBtnRight label {
    font-size: 14px;
  }
  .inputsHolder {
    padding: 0px 16px;
  }
  .stepTwoForm {
    padding: 0px 8px;
  }
  .stepTwoTitle {
    font-weight: bold;
    font-size: 26px;
    margin-top: 12px;
  }
  .stepTwoDescription {
    padding: 0 16px;
  }
  .labelHolder {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .labelTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: fit-content;
  }
  .labelValue {
    font-size: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
  }
  .labelNumber {
    width: 56px;
  }
  .buttonsHolder {
    padding: 24px 0px;
    flex-direction: column-reverse;
  }
  .buttonLeft {
    width: 100%;
    margin: 0px;
  }
  .buttonRight {
    padding: 20px 29px 18px;
    width: 100%;
    margin: 0px;
    margin-bottom: 12px;
  }
  .slider {
    width: 65%;
    margin: 0 auto;
    padding-bottom: 140px;
  }
  .rangePlusSlider {
    position: absolute;
    right: 0%;
    transform: translate(130%, -50%);
  }
  .rangeMinusSlider {
    position: absolute;
    left: 0%;
    transform: translate(-130%, -50%);
  }
  .rangeIconMinus {
    width: 40px;
    height: 40px;
  }

  .rangeIconPlus {
    width: 40px;
    height: 40px;
  }
  .totalLabel {
    font-size: 16px;
  }
  .total {
    font-size: 24px;
  }
  .valueLabel {
    width: 65%;
  }
}
</style>
