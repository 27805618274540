// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes BaseTooltip_showTooltip_1bNNV{0%{opacity:.3}to{opacity:1}}@keyframes BaseTooltip_showTooltip_1bNNV{0%{opacity:.3}to{opacity:1}}@-webkit-keyframes BaseTooltip_hideTooltip_2rFL3{0%{opacity:1}to{opacity:0}}@keyframes BaseTooltip_hideTooltip_2rFL3{0%{opacity:1}to{opacity:0}}.BaseTooltip_tooltipComponent_35CEq{position:relative}.BaseTooltip_tooltipComponent_35CEq.BaseTooltip_positionAbsolute_2RL_n{position:absolute;width:27px;bottom:-4px;right:0;transform:translateX(100%)}.BaseTooltip_tooltipComponent_35CEq.BaseTooltip_tableTooltip_Kt-tp{bottom:0}.BaseTooltip_tooltipComponent_35CEq.BaseTooltip_landTableTooltip_3x1qV{right:unset}.BaseTooltip_fullWidth_o-lLZ,.BaseTooltip_wrapper_2ieCQ{width:100%}.BaseTooltip_wrapper_2ieCQ{-webkit-animation:BaseTooltip_showTooltip_1bNNV .5s;animation:BaseTooltip_showTooltip_1bNNV .5s;display:flex;justify-content:center;position:absolute;bottom:calc(100% + 20px);left:50%;transform:translateX(-50%);border-radius:6px;background:#fff;min-width:200px;max-width:500px;padding:15px;line-height:1.2;font-size:16px;text-align:center;font-weight:400;z-index:999999999999999;box-shadow:0 4px 6px rgba(0,0,0,.2)}.BaseTooltip_wrapper_2ieCQ .BaseTooltip_insideBox_cdQ8P{width:100%;height:100%;-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto}.BaseTooltip_wrapper_2ieCQ .BaseTooltip_cornerTop_i0q0F{display:none}.BaseTooltip_wrapper_2ieCQ .BaseTooltip_cornerBottom_3H4q7{position:absolute;width:30px;bottom:0;left:50%;transform:rotate(180deg) translate(50%,-97%);-webkit-backface-visibility:hidden;backface-visibility:hidden}.BaseTooltip_htmlText_2wxKy{font-size:16px;color:#444;text-align:center}.BaseTooltip_phone_3PtSB .BaseTooltip_wrapper_2ieCQ{min-width:200px;max-width:200px}.BaseTooltip_small_2ad7e .BaseTooltip_wrapper_2ieCQ{min-width:125px;max-width:125px;padding:10px}", ""]);
// Exports
exports.locals = {
	"tooltipComponent": "BaseTooltip_tooltipComponent_35CEq",
	"positionAbsolute": "BaseTooltip_positionAbsolute_2RL_n",
	"tableTooltip": "BaseTooltip_tableTooltip_Kt-tp",
	"landTableTooltip": "BaseTooltip_landTableTooltip_3x1qV",
	"fullWidth": "BaseTooltip_fullWidth_o-lLZ",
	"wrapper": "BaseTooltip_wrapper_2ieCQ",
	"showTooltip": "BaseTooltip_showTooltip_1bNNV",
	"insideBox": "BaseTooltip_insideBox_cdQ8P",
	"cornerTop": "BaseTooltip_cornerTop_i0q0F",
	"cornerBottom": "BaseTooltip_cornerBottom_3H4q7",
	"htmlText": "BaseTooltip_htmlText_2wxKy",
	"phone": "BaseTooltip_phone_3PtSB",
	"small": "BaseTooltip_small_2ad7e",
	"hideTooltip": "BaseTooltip_hideTooltip_2rFL3"
};
module.exports = exports;
