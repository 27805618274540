<template>
  <div
    :class="{
      [$style.tooltipComponent]: true,
      [$style.fullWidth]: fullWidth,
      [$style.land]: el.is.land,
      [$style.port]: el.is.port,
      [$style.phone]: el.is.phone,
      [$style.small]: el.is.small,
      [$style.positionAbsolute]: position === 'absolute',
      [$style.tableTooltip]: table,
      [$style.landTableTooltip]: el.is.land && table,
    }"
    @mouseover="showOnHover = true"
    @mouseleave="showOnHover = false"
  >
    <!-- if regular text -->
    <div
      :class="{
        [$style.wrapper]: true,
      }"
      v-if="toShow && !htmlText"
    >
      <div :class="$style.insideBox">{{ text }}</div>
      <img :class="$style.cornerBottom" src="@/assets/bubble-corner-top.svg" />
      <img :class="$style.cornerTop" src="@/assets/bubble-corner-top.svg" />
    </div>
    <!-- if html text -->
    <div
      :class="{
        [$style.wrapper]: true,
        [$style.htmlText]: true,
      }"
      v-if="toShow && htmlText"
    >
      <div :class="$style.insideBox" v-html="htmlText"></div>
      <img :class="$style.cornerBottom" src="@/assets/bubble-corner-top.svg" />
      <img :class="$style.cornerTop" src="@/assets/bubble-corner-top.svg" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    // problem with refresh on production with inputs
    // if false --> width is set in child component (inputs)
    table: { type: Boolean, default: false },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
    hoverMode: {
      type: Boolean,
      default: true,
    },
    htmlText: {
      type: String,
    },
    position: {
      type: String,
    },
  },
  data() {
    return {
      showOnHover: false,
    }
  },
  computed: {
    el() {
      return store.state.el
    },
    toShow() {
      return this.hoverMode ? this.showOnHover : this.show
    },
  },
}
</script>

<style lang="scss" module>
@keyframes showTooltip {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hideTooltip {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tooltipComponent {
  position: relative;

  // computed
  &.positionAbsolute {
    position: absolute;
    width: 27px;
    bottom: -4px;
    right: 0;
    transform: translateX(100%);
  }
  &.tableTooltip {
    bottom: 0px;
  }
  &.landTableTooltip {
    right: unset;
  }
}
.fullWidth {
  width: 100%;
}
.wrapper {
  animation: showTooltip 0.5s;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  background: #ffffff;
  width: 100%;
  min-width: 200px;
  max-width: 500px;
  padding: 15px;
  line-height: 1.2;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  z-index: 999999999999999;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  .insideBox {
    width: 100%;
    height: 100%;
    hyphens: auto;
  }
  .cornerTop {
    display: none;
  }
  .cornerBottom {
    position: absolute;
    width: 30px;
    bottom: 0;
    left: 50%;
    transform: rotate(180deg) translate(50%, -97%);
    backface-visibility: hidden;
  }
}
.htmlText {
  font-size: 16px;
  color: #444444;
  text-align: center;
}
.phone {
  .wrapper {
    min-width: 200px;
    max-width: 200px;
  }
}
.small {
  .wrapper {
    min-width: 125px;
    max-width: 125px;
    padding: 10px;
  }
}
</style>
